<script lang="ts">
/* icon组件
 * @Author: lich
 * @Date: 2022-02-16 13:31:42
 * @Last Modified by: lich
 * @Last Modified time: 2022-11-22 15:15:55
 * @example
 * <SvgIcon name=''></SvgIcon>
 */
//https://segmentfault.com/a/1190000039255368?utm_source=sf-similar-article
//https://github.com/JetBrains/svg-sprite-loader/issues/434
import { computed, type CSSProperties, defineComponent, type PropType } from "vue";
export default defineComponent({
    name: "SvgIcon",
    props: {
        prefix: {
            type: String,
            default: "icon",
        },
        name: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const symbolId = computed(() => `#${props.prefix}-${props.name}`);
        const svgClass = computed(() => {
            if (props.name) {
                return `svg-icon icon-${props.name}`;
            }
            return "svg-icon";
        });

        const svgStyle = computed(() => {
            let styles: CSSProperties = {};
            if (props.color) {
                styles.color = props.color;
            }
            if (props.size) {
                styles.fontSize = props.size;
            }
            return styles;
        });
        return {
            symbolId,
            svgClass,
            svgStyle,
        };
    },
});
</script>

<template>
    <svg :class="svgClass" v-bind="$attrs" :style="{ ...svgStyle }">
        <use :xlink:href="symbolId" />
    </svg>
</template>

<style lang="scss" scoped>
.svg-icon {
    // display: inline-block;
    // width: 1em;
    // height: 1em;
    // fill: currentColor;
    // vertical-align: middle;
}
</style>
